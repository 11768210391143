





























































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Avis } from "@/api/models/avis/avis";
import { UtilisateurAvisModel } from "@/api/models/utilisateurs/utilisateur";

import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BTooltip,
    BFormTextarea,
  },
})
export default class ListAvis extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  searchQuery = "";
  isSortDirDesc = false;
  sortBy = "libelle";

  userDetails: UtilisateurAvisModel = {};

  tableColumns = [
    { key: "note", sortable: true },
    { key: "utilisateur", sortable: true },
    { key: "actions", sortable: false },
  ];
  paginatedListAvis = new PaginatedList<Avis>();

  async created() {
    await this.loadListAvis({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
    });
  }

  async showUser(user: any) {
    this.userDetails = user;
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListAvis.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListAvis.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  @Watch("searchQuery")
  async paginationChanged() {
    await this.loadListAvis({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
    });
  }

  updateSort(params: any) {
    this.sortBy = params.sortBy;
    params = {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc,
    };
    this.isSortDirDesc = !this.isSortDirDesc;
    this.loadListAvis(params);
  }

  async loadListAvis(params: any) {
    if (!params || params?.reset) {
      this.paginatedListAvis = new PaginatedList<Avis>();
    }
    await this.$http.myressif.avis
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Avis>) => {
        this.paginatedListAvis = response;
      });
  }
}
